<template>
  <v-container
    fluid
    fill-height
  >
    <v-row>
      <v-col
        cols="3"
      >
        <CaseModelCategories
          :actual-tab="actualTab"
        />
      </v-col>
      <v-col
        cols="9"
      >
        <CaseModelGeneralOverview
          @tabChanged="onTabChanged"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CaseModelCategories from './CaseModelCategories.vue'
import CaseModelGeneralOverview from './CaseModelGeneralOverview.vue'

export default {
  components: { CaseModelCategories, CaseModelGeneralOverview },
  data () {
    return {
      lavviraTab: false,
      actualTab: null
    }
  },
  methods: {
    onTabChanged (actualTab) {
      this.actualTab = actualTab
      if (actualTab.isLavviraTab) {
        this.lavviraTab = true
        return this.lavviraTab
      } else {
        this.lavviraTab = false
        return this.lavviraTab
      }
    }
  }
}
</script>
